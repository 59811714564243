import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { ModalLayout, Text, useTranslate } from 'decryption_component-kit';
import {
  AdaptedPromoCodeResponse,
  EPromoCodeType,
} from 'decryption_protocol/dist/lib/store/user/entities/promoCode/type';

import {
  closeAndOpenModalAction,
  getModalAdditionalInfoSelector,
} from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { ModalTypes } from 'stores/modal/types';

import ComponentKitBaseButton from 'components/ui/KitComponents/Buttons/BaseButton';

import { PROMO_CODE_RESPONSE } from './constants';
import { PromoCodeRowProps } from './components/PromoCodeRow';
import { formattedDate } from 'utils/date';
import { ERouteNames } from 'Routes/types';

import styles from './PromoCodeActivateModal.module.css';

const PromoCodeActivateModal = () => {
  const setModal = useZustandModalStore(closeAndOpenModalAction);

  const { getText } = useTranslate('', '');

  const navigate = useNavigate();

  const {
    typeBonus,
    ...rest
  }: { [key: string]: string | number; typeBonus: EPromoCodeType } =
    useZustandModalStore(getModalAdditionalInfoSelector) ||
    ({
      typeBonus: EPromoCodeType.Undefined,
    } as AdaptedPromoCodeResponse);

  const list: PromoCodeRowProps[] = useMemo(() => {
    if (typeBonus === EPromoCodeType.Undefined) {
      return [];
    }
    const keys = Object.keys(rest) as Array<keyof AdaptedPromoCodeResponse>;

    const list: PromoCodeRowProps[] = [];

    keys.forEach((key) => {
      const isDate = key === 'bonusTime';

      if (typeBonus === EPromoCodeType.RakebackBoost && key === 'bonusOdd') {
        return true;
      }

      const formatDate = isDate ? formattedDate(+rest[key]) : ['', ''];

      const value = isDate
        ? `${formatDate[0]} ${getText('counter.dd')} ${formatDate[1]} ${getText(
            'counter.hh'
          )}`
        : rest[key];

      if (+value < 0) {
        return true;
      }

      if (rest[key]) {
        list.push({
          title: `promoCodePage.${key}`,
          value: key === 'bonusOdd' ? value + '%' : value,
        });
      }
    });

    switch (typeBonus) {
      case EPromoCodeType.BonusCash:
      case EPromoCodeType.RakebackBoost:
      case EPromoCodeType.BonusDeposit:
      case EPromoCodeType.BonusPackActivation:
      case EPromoCodeType.Cash:
      case EPromoCodeType.ERG:
      case EPromoCodeType.FreeSpins:
      case EPromoCodeType.FtdBonusDepositCoeff:
        return [
          {
            title: `promoCodePage.${EPromoCodeType[typeBonus]}`,
            value: 'promoCodePage.activated',
          },
          ...list,
        ];

      default:
        return list;
    }
  }, [typeBonus, rest]);

  const { name: title } = useTranslate(
    'promoCodePage.promoCodeActivatedTitle',
    ''
  );

  const onCloseModal = (type: ModalTypes) => () => {
    switch (typeBonus) {
      case EPromoCodeType.BonusCash:
        navigate(ERouteNames.FrameBetting);

        return setModal(ModalTypes.Unknown);
      case EPromoCodeType.Cash:
        navigate(ERouteNames.CasinoHome);

        return setModal(ModalTypes.Unknown);

      default:
        setModal(type);
    }
  };

  const AdditionalInfo = PROMO_CODE_RESPONSE[typeBonus];

  return (
    <ModalLayout
      classes={styles.wrapperLootBoxGame}
      popUpHeaderProps={{
        title,
      }}
      buttonClose={onCloseModal(ModalTypes.Unknown)}
    >
      <>
        <div className={styles.description}>
          <Text idT="promoCodePage.promoCodeBonusCabinet" />
        </div>

        <img
          src="/icons-elon/promo-code/promo-active.png"
          className={styles.img}
        />

        <AdditionalInfo list={list} specV={rest.bonusOdd} />

        <ComponentKitBaseButton
          text="promoCodePage.promoCodeBonusCabinetBtn"
          size="--button-full"
          buttonHeight="--button-height-full"
          color="primary"
          centreText
          handleClick={onCloseModal(ModalTypes.Bonuses)}
        />
      </>
    </ModalLayout>
  );
};

export default PromoCodeActivateModal;
